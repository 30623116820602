import React, { useContext } from 'react'; // Import useContext
import { View, Text, Image, StyleSheet, Dimensions, Linking } from 'react-native';
import AppContext from '~/contexts/AppContext';
import iconBack from '~/assets/images/iconBack.png'
import arrow from '~/assets/images/arrow.png'
import chevronLeft from '~/assets/images/chevronLeft.png'
import { TouchableOpacity } from 'react-native-web';
const screenHeight = Dimensions.get('window').height;
const screenWidth = Dimensions.get('window').width;
// Header component
const Header = ({ 
    AvatarIsPicked, 
    setAvatarIsPicked, 
    setElisonIsPicked,
    setActiveVideoIndex, 
    setIsFullscreen, 
    isFullscreen, 
    isMobileDesign, 
    setIsSummary,
    ChatGPTAIService
  }) => {
  // Use useContext inside the functional component
  const { responsiveHeight,responsiveWidth } = useContext(AppContext);

  return (
    <View style={[styles.container]}>
      { !isFullscreen && (
        <>
          <View style={{flex: isMobileDesign ? 1 : 4, flexDirection:'row', marginLeft: '5%'}}>
            { !(AvatarIsPicked && isMobileDesign) && (
              <TouchableOpacity
                onPress={() => (window.self === window.top) && parent.window.open("https://elysian-softech.com/" , "_self")}
              >
                <Image source={iconBack} style={styles.back}/>
              </TouchableOpacity>
            )}
            { !AvatarIsPicked ? (
              <Text style={styles.title}>Elysian - Softech Interactive AI</Text>
            ) : (
              <TouchableOpacity onPress={() => {
                  setAvatarIsPicked(false); 
                  setElisonIsPicked(false);
                  setActiveVideoIndex(0);
                  setIsFullscreen(false);
                  setIsSummary(true);
                  ChatGPTAIService.conversation = [];
                }}>
                <View style={[!isMobileDesign ? styles.arrow : styles.arrowMobile, (window.self!==window.top && isMobileDesign) && {marginLeft: 40}]}>
                  <Image source={isMobileDesign ? chevronLeft : arrow} style={styles.arrowImage} /> 
                </View>
              </TouchableOpacity>
          )}
          </View>
          {!isMobileDesign && <View style={{flex:6}}></View>}
        </>
      )}
    </View>
  );
};

export default Header;

const styles = StyleSheet.create({
  container: {
    zIndex: 5,
    flexDirection: 'row',
    position: 'absolute',
    alignSelf: 'flex-start',
    width: '100%',
    height: 50,
    marginTop: 44,
    gap: 20,
  },
  title: {
    backgroundColor: '#5A6BF7',
    height: 43,
    width: 228,
    color: '#ffffff',
    lineHeight: 24,
    marginLeft: '20%',
    paddingHorizontal: 14,
    paddingVertical: 10,
  },
  back: {
    width: 30,
    height: 43,
  },
  arrow: {
    width: 54,
    height: 43,
    backgroundColor: '#5A6BF7',
    marginLeft: 50,
    justifyContent: 'center'
  },
  arrowMobile: {
    backgroundColor: 'transparent',
  },
  arrowImage: {
    width: 30,
    height: 17,
    alignSelf: 'center'
  }
})