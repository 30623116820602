import React, { useEffect, useContext, useState } from 'react';
import { TouchableOpacity, Image } from 'react-native';
import AppContext from '~/contexts/AppContext';
import * as speechsdk from 'microsoft-cognitiveservices-speech-sdk';

const SpeechToTextRecorderPat = ({ onTranscript, lang }) => {
    const { isRecording, setIsRecording } = useContext(AppContext);
    const [speechRecognizer, setSpeechRecognizer] = useState(null);

    useEffect(() => {
        const setupRecognizer = async () => {
            const tokenObj = await getTokenOrRefresh(); // Implement this function to fetch auth token
            const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(tokenObj.authToken, tokenObj.region);
            speechConfig.speechRecognitionLanguage = lang ? 'he-IL' : 'en-US';

            const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();
            const recognizer = new speechsdk.SpeechRecognizer(speechConfig, audioConfig);

            recognizer.recognizing = (s, e) => {
                console.log(`RECOGNIZING: Text=${e.result.text}`);
            };

            recognizer.recognized = (s, e) => {
                if (e.result.reason === speechsdk.ResultReason.RecognizedSpeech) {
                    console.log(`RECOGNIZED: Text=${e.result.text}`);
                    onTranscript(e.result.text);
                }
            };

            recognizer.canceled = (s, e) => {
                console.log(`CANCELED: Reason=${e.reason}`);
                if (e.reason === speechsdk.CancellationReason.Error) {
                    console.log(`CANCELED: ErrorCode=${e.errorCode}`);
                    console.log(`CANCELED: ErrorDetails=${e.errorDetails}`);
                    console.log(`CANCELED: Did you update the subscription info?`);
                }
                
                recognizer.stopContinuousRecognitionAsync();
                setIsRecording(false);
                
            };

            recognizer.sessionStopped = (s, e) => {
                console.log("Session stopped.");
                recognizer.stopContinuousRecognitionAsync();
                playBeep('end');
                setIsRecording(false);
            };

            setSpeechRecognizer(recognizer);
        };

        setupRecognizer();

        return () => {
            speechRecognizer?.close();
            
        };
    }, []);

    useEffect(() => {
        if (isRecording) {
            speechRecognizer?.startContinuousRecognitionAsync();
            playBeep('start');
        } else {
            speechRecognizer?.stopContinuousRecognitionAsync();
            
        }
    }, [isRecording, speechRecognizer]);

    const handleToggleRecording = () => {
        setIsRecording(!isRecording);
    };

    return (
        <TouchableOpacity
            onPress={handleToggleRecording}
            style={{ justifyContent: 'center', marginRight: 5 }}>
            <Image
                source={isRecording ? require('~/assets/images/micBtnActive.png') : require('~/assets/images/micBtnInactive.png')}
                style={{ width: 35, height: 35 }}
            />
        </TouchableOpacity>
    );
};

const playBeep = (type) => {
    const sound = new Audio(require(`~/assets/audio/${type === 'start' ? 'startMic.mp3' : 'stopMic.mp3'}`));
    sound.play();
};



async function getTokenOrRefresh() {
    const subscriptionKey = 'c7b16d1398724866b3420ee2ecb93c07';
    const region = 'eastus';
  
    const fetchUrl = `https://${region}.api.cognitive.microsoft.com/sts/v1.0/issueToken`;
    const headers = {
      'Ocp-Apim-Subscription-Key': subscriptionKey,
      'Content-Type': 'application/x-www-form-urlencoded'
    };
  
    try {
      const response = await fetch(fetchUrl, {
        method: 'POST',
        headers: headers
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch the token, status: ' + response.status);
      }
  
      const authToken = await response.text(); // Token is returned as plain text
      return { authToken, region };
    } catch (error) {
      console.error('Error fetching token:', error);
      throw error; // Re-throw to handle it later
    }
  }
  

export default SpeechToTextRecorderPat;
