import React, { useEffect, useRef,useState } from 'react';
import { ScrollView, Dimensions, View } from 'react-native';
import Message from './Message';
const screenHeight = Dimensions.get('window').height;
import { vw, vh, vmin, vmax } from 'react-native-expo-viewport-units';

const MessageList = ({ messages, userImage, botImage,scroll }) => {
  const scrollViewRef = useRef();
  const [messageHeight,setMessageHeight] = useState(screenHeight);

  useEffect(() => {
    if(scroll){
    scrollViewRef.current?.scrollToEnd({ animated: true });
  }
  }, [messages]); // Dependency array with messages

  useEffect(() => {
    setMessageHeight(screenHeight)
  },[]);

  return (
    <ScrollView
      style={{ flex: 1}}
      ref={scrollViewRef}
       // Attach the ref here
    >
      <View style={[{height: messageHeight - 212 - 65, marginTop:20}, window.self!==window.top && {height:messageHeight - 212 - 85}]}>
        {messages.map((message, index) => (
          <Message
            key={index}
            text={message.text}
            sentByUser={message.sentByUser}
            userImage={userImage}
            botImage={botImage}
          />
        ))}
      </View>
    </ScrollView>
  );
};

export default MessageList;
