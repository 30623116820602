import React, { useState, useRef, useEffect, useContext } from 'react'; // Import useContext
import { View, TextInput, TouchableOpacity, Image, StyleSheet, Text, Dimensions } from 'react-native';
import SpeechToTextRecorderPat from './SpeechToTextRecorder';
import OCR from './OCR'; // Import the OCR component
import AppContext from '~/contexts/AppContext'; // Import AppContext
import { Keyboard } from 'react-native';
const screenWidth = Dimensions.get('window').width;

const ChatInputPat = ({ onSend, isSendingDisabled, onFileSelected,lang,focus, sendSummaryBtn, ChatGPTAIService, isMobileDesign }) => {
  const [inputText, setInputText] = useState('');
  const inputRef = useRef(null);
  const { responsiveHeight } = useContext(AppContext); // Use useContext inside the component

  useEffect(() => {
    if(localStorage.getItem("phone")=="no"){
    if(focus){
    inputRef.current?.focus();
  }
}
  }, []);

  const handleSend = () => {
    if (inputText.trim()) {
      onSend(inputText);
      setInputText('');
      if(localStorage.getItem("phone")=="no"){
      setTimeout(() => inputRef.current?.focus(), 0); 
      }
      Keyboard.dismiss();
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); 
      handleSend();
    }
  };

  // Define styles inside the component to use responsiveHeigh

  return (
    <View style={styles.container}>
    <OCR onFileSelected={onFileSelected} />

      <TextInput
        ref={inputRef}
        style={{...styles.input,outline:'none'}}
        value={inputText}
        onChangeText={setInputText}
        placeholder="Type message here..."
        placeholderTextColor="black"
        onSubmitEditing={handleSend}
        onKeyPress={handleKeyPress}
        editable={!isSendingDisabled}
      />
      <SpeechToTextRecorderPat onTranscript={onSend} lang={lang} />

      <TouchableOpacity
        onPress={handleSend}
        style={styles.sendButton}
        disabled={inputText.trim().length === 0}>
        <Image
          source={inputText.trim().length > 0 ? require('~/assets/images/sendBtnActive.png') : require('~/assets/images/sendBtnInactive.png')}
          style={{ width: 35, height: 35 }}
        />
      </TouchableOpacity>
      <TouchableOpacity
        onPress={sendSummaryBtn}
        style={[styles.summaryButton, ChatGPTAIService.conversation.length < 10 && {opacity: 0.5}]}
        disabled={ChatGPTAIService.conversation.length < 10}>
        <Text style={styles.summaryButtonText}>{isMobileDesign ? "Sum" : "Summarize"}</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: '#474747',
    alignItems: 'center',
    zIndex: 2,
    width: screenWidth < 1000 ? screenWidth : '100%'
  },
  input: {
    flex: 1,
    backgroundColor: 'white',
    height: 30,
    paddingVertical: 10,
    alignItems: 'center',
    fontSize: 16,
    paddingHorizontal: 10,
    marginRight: 5,
    maxWidth: screenWidth - 180
  },
  sendButton: {
    justifyContent: 'center',
  },
  summaryButton: {
    justifyContent: 'center',
    backgroundColor: '#5A6BF7',
    padding: 10,
    marginLeft: 5
  },
  summaryButtonText: {
    color: '#ffffff'
  }
});

export default ChatInputPat;
