const fetchTTS = async (text, ID) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'xi-api-key': '8e54a58bc3bb3e7daf0827b807bdbbdb'
    },
    body: JSON.stringify({
      model_id: "eleven_monolingual_v1",
      voice_settings: { similarity_boost: 1.0, stability: 1.0 },
      pronunciation_dictionary_locators: [],
      text: text
    })
  };

  try {
    const response = await fetch(`https://api.elevenlabs.io/v1/text-to-speech/${ID}/stream`, options);
    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const blob = await response.blob();
    const audioUrl = URL.createObjectURL(blob);
    console.log(audioUrl);
    return audioUrl;
  } catch (err) {
    console.error(err);
    throw err;
  }

};

export const fetchAzureTTS = async (text) => {
  const subscriptionKey = 'c7b16d1398724866b3420ee2ecb93c07';
  const region = 'eastus';
  const ttsUri = `https://${region}.tts.speech.microsoft.com/cognitiveservices/v1`;
  const rate = '5%', pitch = '-10%'

  const ssml = `<speak version='1.0' xmlns='http://www.w3.org/2001/10/synthesis' xml:lang='he-IL'>
    <voice name='he-IL-HilaNeural'>
      <prosody rate='${rate}' pitch='${pitch}'>${text}</prosody>
    </voice>
  </speak>`;

  try {
    const response = await fetch(ttsUri, {
      method: 'POST',
      headers: {
        'Ocp-Apim-Subscription-Key': subscriptionKey,
        'Content-Type': 'application/ssml+xml',
        'X-Microsoft-OutputFormat': 'audio-16khz-128kbitrate-mono-mp3'
      },
      body: ssml
    });

    if (!response.ok) {
      throw new Error(`Error from Azure TTS: ${response.statusText}`);
    }

    const audioData = await response.arrayBuffer();
    const audioBlob = new Blob([audioData], { type: 'audio/mp3' });
    const audioUrl = window.URL.createObjectURL(audioBlob);
    return audioUrl;
  } catch (error) {
    console.error('Error with Azure TTS request:', error);
    return null;
  }
};

export default fetchTTS;
