import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

const Partners = ({isMobileDesign}) => {
    return (
        <View style={[styles.container, isMobileDesign && {width: '96%', height: 400}]}>
            <View style={[styles.partner, isMobileDesign && {height: 65}]}>
                <Text style={styles.name}>Tomer
                <Text style={styles.desc}>CEO & Software Architect: The visionary leader and innovative force behind our cutting-edge technology.</Text></Text>
            </View>
            <View style={[styles.partner, isMobileDesign && {height: 65}]}>
                <Text style={styles.name}>Aviad
                <Text style={styles.desc}>VP Customer Relations: Renowned for his exceptional ability to connect with clients and ensure their utmost satisfaction.</Text></Text>
            </View>
            <View style={[styles.partner, isMobileDesign && {height: 65}]}>
                <Text style={styles.name}>Adrian
                <Text style={styles.desc}>CBO: A strategic thinker and business growth expert, driving the company towards exciting new markets.</Text></Text>
            </View>
            <View style={[styles.partner, isMobileDesign && {height: 65}]}>
                <Text style={styles.name}>Assaf
                <Text style={styles.desc}>CFO & CADV: The financial wizard and analytical mind safeguarding our company's economic health and future.</Text></Text>
            </View>
            <View style={[styles.partner, isMobileDesign && {height: 65}]}>
                <Text style={styles.name}>Yuri
                <Text style={styles.desc}>COO: The operational maestro, ensuring seamless processes and the pinnacle of efficiency in our operations.</Text></Text>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        width: 1000,
        height: 170,
        alignItems: 'left',
        alignSelf: 'center',
        backgroundColor: '#1E1F26',
        paddingHorizontal: 20,
        paddingVertical: 12,
        marginTop: 20
    },
    partner: {
        flexDirection: 'row',
        height: 21,
        marginBottom: 10
    },
    name: {
        color: '#5A6BF7',
        fontSize: 16,
        lineHeight: 20,
    },
    desc: {
        color: '#C7C8D8',
        fontSize: 16,
        lineHeight: 20,
        marginLeft: 10
    },
})

export default Partners;