// Customer.js

import React, { useContext, useEffect, useRef, useState } from 'react';
import { View, StyleSheet, Text, Dimensions, ScrollView } from 'react-native';

import Header from '~/components/Header';
import Avatar from '~/components/Avatar';
import MainTitle from '~/components/MainTitle';
import Partners from '~/components/Partners';
import MessageList from '~/components/MessageList';
import ChatInput from '~/components/ChatInput';
import ChatGPTAIService from '~/services/ChatGPTAIService';
import UserImage from '~/assets/images/imageCustomer.png';
import AppContext from '~/contexts/AppContext';
import TermsModal from '~/components/TermsModal';
import TomerVideo from '~/assets/videos/Tomer.mp4';
import AvaidVideo from '~/assets/videos/Aviad.mp4';
import YuriVideo from '~/assets/videos/Yuri.mp4';
import AssafVideo from '~/assets/videos/Assaf.mp4';
import AdrianVideo from '~/assets/videos/Adrian.mp4';
import Elison from '~/assets/videos/Elison.mp4';
import instructions from '~/data/instructions.json'
import TomerPic from '~/assets/images/Tomer.jpg'
import AviadPic from '~/assets/images/Aviad.jpg'
import AdrianPic from '~/assets/images/Adrian.jpg'
import AssafPic from '~/assets/images/Assaf.jpg'
import YuriPic from '~/assets/images/Yuri.jpg'
import emailjs from 'emailjs-com';
import fetchTTS, { fetchAzureTTS } from '~/services/TTsService'
import Adrian1 from '~/assets/videos/Adrian1.mp4';
import Adrian2 from '~/assets/videos/Adrian2.mp4';
import Adrian3 from '~/assets/videos/Adrian3.mp4';
import Yuri1 from '~/assets/videos/Yuri1.mp4';
import Yuri2 from '~/assets/videos/Yuri2.mp4';
import Yuri3 from '~/assets/videos/Yuri3.mp4';
import Assaf1 from '~/assets/videos/Assaf1.mp4';
import Assaf2 from '~/assets/videos/Assaf2.mp4';
import Assaf3 from '~/assets/videos/Assaf3.mp4';
import Avaid1 from '~/assets/videos/Aviad1.mp4';
import Avaid2 from '~/assets/videos/Aviad2.mp4';
import Avaid3 from '~/assets/videos/Aviad3.mp4';
import Tomer1 from '~/assets/videos/Tomer1.mp4';
import Tomer2 from '~/assets/videos/Tomer2.mp4';
import Tomer3 from '~/assets/videos/Tomer3.mp4';
import Elison1 from '~/assets/videos/Elison1.mp4';
import Elison2 from '~/assets/videos/Elison2.mp4';
import Elison3 from '~/assets/videos/Elison3.mp4';
import ElisonPic from '~/assets/images/AIElison.jpeg'
import { generatePDF } from '~/services/pdfService';
import ReactPixel from 'react-facebook-pixel';
import { TouchableOpacity } from 'react-native-web';
import { vw, vh, vmin, vmax } from 'react-native-expo-viewport-units';

const screenWidth = Dimensions.get('window').width;
const screenHeight = Dimensions.get('window').height;


// Adjust width and height based on screen dimensions to maintain aspect ratio
export default function CustomerPage() {
  const [modalVisible, setModalVisible] = useState(false);
  const { customerMessages, setCustomerMessages, isStreaming } = useContext(AppContext);
  const [messages, setMessages] = useState(customerMessages);
  const [latestResponse, setLatestResponse] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [AvatarIsPicked, setAvatarIsPicked] = useState(false);
  const AvatarIsPickedRef = useRef(false);
  const [voiceID, setVoiceID] = useState("BgdXu2jujmRAAzPs7gNN");
  const [ttsAudioUrl, setTtsAudioUrl] = useState(null);
  const [videoSource, setVideoSource] = useState(Elison);
  const [avatarLabel, setAvatarLabel] = useState('גברת אליסון\n דוברת עברית');
  const [botImage, setBotImage] = useState(ElisonPic);
  const [videoSources, setVideoSources] = useState([Tomer1, Tomer2, Tomer3]);
  const [ElisonIsPicked, setElisonIsPicked] = useState(false);
  const ElisonIsPickedRef = useRef(false);
  const [videoStream, setVideoStream] = useState(null);
  const [isFirst, setIsFirst] = useState(false);
  const [isSummary, setIsSummary] = useState(true);
  const chatTimout = useRef();
  const [activeVideoIndex, setActiveVideoIndex] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isMobileDesign, setIsMobileDesign] = useState(false);
  const scrollViewRefMain = useRef();
  const uniqueId = Math.floor(Math.random() * 100000) + 1;
  const isStreamingRef = useRef(false);

  let startTime;



  const changeVideo = async (avatarName) => {
    window.scrollTo(0,0);
    setAvatarIsPicked(true);
    AvatarIsPickedRef.current=true;

    switch (avatarName) {
      case 'Tomer':
        setAvatarLabel("Mr. Tomer Paz\nEnglish Speaker");
        setVideoSource(TomerVideo);
        setBotImage(TomerPic);
        sendInitialMessage("Tomer Paz - the CEO & Software Architect", "Tomer", instructions.tomer_instructions);
        setVideoSources([Tomer1, Tomer2, Tomer3]);
        console.log(Tomer1);
        setVoiceID("fIdR6vJWGZypitdCsqgC");
        break;
      case 'Aviad':
        setAvatarLabel("Mr. Aviad Afengar\nEnglish Speaker");
        setBotImage(AviadPic);
        setVideoSource(AvaidVideo);
        sendInitialMessage("Aviad Afengar - the Vice President of Customers Relations", "Aviad", instructions.aviad_instructions);
        setVideoSources([Avaid1, Avaid2, Avaid3]);
        setVoiceID("vNXqylVACQFTw5rT363o");

        break;
      case 'Adrian':
        setAvatarLabel("Dr. Adrian Paz\nEnglish Speaker");
        setBotImage(AdrianPic);
        setVideoSource(AdrianVideo);
        sendInitialMessage("Dr. Adrian Paz - the Chief Business Officer(CBO) of the company", "Adrian", instructions.adrian_instructions);
        setVoiceID("fJE3lSefh7YI494JMYYz");
        setVideoSources([Adrian1, Adrian2, Adrian3]);
        break;
      case 'Assaf':
        setAvatarLabel("Mr. Assaf Matza\nEnglish Speaker");
        setBotImage(AssafPic);
        setVideoSource(AssafVideo);
        sendInitialMessage("Assaf Matza - the CFO and Chief Advocates", "Assaf", instructions.assaf_instructions);
        setVideoSources([Assaf1, Assaf2, Assaf3]);
        setVoiceID("rV7LntY9aTpIGaWM9qIe");
        break;
      case 'Yuri':
        setAvatarLabel("Mr. Yuri Paikin\nEnglish Speaker");
        setBotImage(YuriPic)
        setVideoSource(YuriVideo);
        sendInitialMessage("Yuri Paikin - the Chief Operation Officer(COO) of the company", "Yuri", instructions.yuri_instructions);
        setVideoSources([Yuri1, Yuri2, Yuri3]);
        setVoiceID("t9pa8vZ7tCoTLCLirl6c");

        break
      case 'Elison':

        setAvatarLabel('גברת אליסון\n דוברת עברית');
        setBotImage(ElisonPic)
        setVideoSource(Elison);
        sendInitialMessage("אליסון - עוזרת הבינה המלאכותית שלך", "Elison", instructions.elison_instructions);
        setVideoSources([Elison3, Elison2, Elison1]);
        setElisonIsPicked(true);
        ElisonIsPickedRef.current = true;

        break
      default:
        setAvatarLabel("Mr. Tomer Paz");
        setVideoSource(TomerVideo);
        setBotImage(TomerPic);
        sendInitialMessage("Tomer Paz - the CEO & Software Architect", "Tomer", instructions.tomer_instructions);
        setVideoSources([Tomer1, Tomer2, Tomer3]);
    }

  }


  const handleAcceptTerms = () => {
    setModalVisible(false);

  };
  //Elison - CAIO: The AI manager, an smart entity keeping the technological management and ongoing efficiency in the company
  const sendInitialMessageByAI = async () => {

    const initialMessage = `By engaging with this AI system, you acknowledge and accept the Privacy Policy and Terms & Conditions, which you can conveniently access at the top-right corner of the screen. Simply tap on the button called \"Privacy & Terms\" , and the full documentation will be available for your review.

Hi there! Welcome to our AI assistant service. Today, you have the unique opportunity to personalize your experience by choosing the persona of one of our esteemed team members. To select, simply tap on the avatar you prefer, located above on the right side of this interface. Here’s a brief introduction to your options, from top to bottom:

1. Tomer - CEO & Software Architect: The visionary leader and innovative force behind our cutting-edge technology.
2. Aviad - VP Customer Relations: Renowned for his exceptional ability to connect with clients and ensure their utmost satisfaction.
3. Adrian - CBO: A strategic thinker and business growth expert, driving the company towards exciting new markets.
4. Assaf - CFO & CADV: The financial wizard and analytical mind safeguarding our company's economic health and future.
5. Yuri - COO: The operational maestro, ensuring seamless processes and the pinnacle of efficiency in our operations.
 
  
Choose the avatar whose attributes you’d like
to see reflected in our conversation today, and let’s embark on a productive session!`;

    const HebrewIntial = ` כאשר אתה משתמש במערכת הזו אתה מאשר שקראת ואתה מאשר את המסמך של תנאי השימוש והפרטיות שנמצא בקצה
 הימני של המסך למעלה. 
 בעבור שירות בעברית לחץ על התמונה של אליסון עוזרת הבינה המלאכותית שלנו.
 התמונה היא תמונת רובוט הבינה המלאכותית שלנו שנמצאת למטה מבין התמונות המופיעות ליד האווטר.`



    setMessages([
      { text: HebrewIntial, sentByUser: false },
      { text: initialMessage, sentByUser: false }
    ]);
    

  };

  let summaryCount = 0;
  const sendInitialMessage = async (avatarDesc, avatarName, system) => {

    //set start time of the conversation
    startTime= new Date();
    ReactPixel.track('Leadbot');
 
    const summaryInterval = 90000; // 1.5 minutes in milliseconds
    const maxSummaries = 7;
    let bool=false;

    const summaryTimerId = setInterval(() => {
      if (summaryCount < maxSummaries) {
        if(summaryCount==maxSummaries-1){
          bool=!bool;
        }
        sendSummary(bool);
        summaryCount++;
      } else {
        clearInterval(summaryTimerId);
      }
    }, summaryInterval);
    window.clearTimeout(chatTimout.current);
    
    chatTimout.current = (setTimeout(() => {
      setIsSummary(false);
    }, 600000))

    let initialMessage = ''
    if (avatarName == 'Elison') {
      initialMessage = `היי מה שלומך אני אליסון עוזרת הבינה המלאכותית שלך, איך אוכל לעזור?`;


    }
    else {
      initialMessage = `Hi there! I'm the AI Assistant of ${avatarDesc}. How can I help you today?`;

    }
    setMessages([{ text: initialMessage, sentByUser: false }]);
    setCustomerMessages([{ text: initialMessage, sentByUser: false }]);
    ChatGPTAIService.addMessageToConversation('system', system);
    ChatGPTAIService.addMessageToConversation('assistant', initialMessage);
    ChatGPTAIService.addMessageWithoutPrompt('assistant', initialMessage);
  };



  const handleFileSelected = async (file, sentByUser = true) => {
    setIsFirst(true);

    if (isSending) return;
    setIsSending(true);

    let fileName = file.fileName
    setMessages(prevMessages => [...prevMessages, { text: fileName, sentByUser }]);
    setCustomerMessages(prevMessages => [...prevMessages, { text: fileName, sentByUser }]);
    if (!sentByUser) return;

    setMessages(prevMessages => [...prevMessages, { text: "typing...", sentByUser: false }]);
    setCustomerMessages(prevMessages => [...prevMessages, { text: "typing...", sentByUser: false }]);


    let response;
    try {
      let ocrEng = ". this is instructions for you: this is an ocr translation of a file the customer just send , answer it like you read the file. dont mention it is an ocr the file should be relavant to the conversation "
      let ocrHeb = ' זה הוראות בשבילך בטקסט הזה יש תרגום תקרא את הטקסט ותתנהג כאילו אתה קראת את הקובץ ממנו נשלח הטקסט הזה ובתשובתך תענה עכשיו כאילו קראת את הקובץ ואתה מדבר איתי עליו '
      let user_instruction = ElisonIsPicked ? ocrHeb : ocrEng;
      if (ChatGPTAIService.getLastRole()=="user"){
        ChatGPTAIService.deleteLastMessageFromConversation();
      }
      ChatGPTAIService.addMessageToConversation('user', file.text + user_instruction);
      ChatGPTAIService.addMessageWithoutPrompt('user', file.text);
      response = await ChatGPTAIService.enqueueRequest(ElisonIsPicked, isStreaming);
      setMessages(prevMessages => {
        const updatedMessages = prevMessages.slice(0, -1);
        updatedMessages.push({ text: response, sentByUser: false });
        return updatedMessages;
      });
      setCustomerMessages(prevMessages => {
        const updatedMessages = prevMessages.slice(0, -1);
        updatedMessages.push({ text: response, sentByUser: false });
        return updatedMessages;
      });

      console.log(isStreaming, isStreamingRef.current);
      if (isStreaming || isStreamingRef.current){
      if (!ElisonIsPicked ) {
        try {
          const ttsData = await fetchTTS(response, voiceID);
          setTtsAudioUrl(ttsData);
        } catch (a) {

        }
      }
      else{

        try {
          const ttsData = await fetchAzureTTS(response);
          setTtsAudioUrl(ttsData);
        } catch (a) {

        }

      }
    }
      

      setLatestResponse(response);
      ChatGPTAIService.addMessageToConversation('assistant', response);
      ChatGPTAIService.addMessageWithoutPrompt('assistant', response);
      
      //sendMessageToMongo('assistant', response, 'customer');


      setIsSending(false);
    } catch (error) {
      console.error('Error in handlefileSelected:', error);
      setIsSending(false);
    }


  };

  const handleVoiceTranscript = async (transcript) => {
    console.log("Transcript:", transcript);
    handleSend(transcript);
  };

  const handleSend = async (text, sentByUser = true) => {

   
    setIsFirst(true);
    if (isSending) return;
    setIsSending(true);

    setMessages(prevMessages => [...prevMessages, { text, sentByUser }]);
    setCustomerMessages(prevMessages => [...prevMessages, { text, sentByUser }]);

    if (!sentByUser) return;

    setMessages(prevMessages => [...prevMessages, { text: "typing...", sentByUser: false }]);
    setCustomerMessages(prevMessages => [...prevMessages, { text: "typing...", sentByUser: false }]);

    

    let response;
    try {
      console.log("conversation length", ChatGPTAIService.conversation.length);
      let user_instruction = instructions["user_instruction1-12"];
      if(ChatGPTAIService.conversation.length>12 && ChatGPTAIService.conversation.length<=18) user_instruction = instructions["user_instruction13-18"];
      if(ChatGPTAIService.conversation.length>18) user_instruction = instructions["user_instruction18+"];
      const prompt = !ElisonIsPicked ? " prompt: " +  user_instruction : "הוראות: "+instructions["user_for_elison"];
      if (ChatGPTAIService.getLastRole()=="user"){
        ChatGPTAIService.deleteLastMessageFromConversation();
        console.log("the last message was user")
      }
      ChatGPTAIService.addMessageToConversation('user', text + prompt);
      ChatGPTAIService.addMessageWithoutPrompt('user', text);

      response = await ChatGPTAIService.enqueueRequest(ElisonIsPicked, isStreaming);
      if (response.toLowerCase().includes('summarize')||response.toLowerCase().includes('summary')||response.toLowerCase().includes('סיכום')) {
        // isNotSummarize=false;
        // setIsSummary(false)
        summaryCount+=7;
        sendEmailWithSummary();
      }
     
      console.log(isStreaming, isStreamingRef.current);
      if (isStreaming || isStreamingRef.current){
        if (!ElisonIsPicked ) {
          try {
            const ttsData = await fetchTTS(response, voiceID);
            setTtsAudioUrl(ttsData);
          } catch (a) {
  
          }
        }
        else{
  
          try {
            const ttsData = await fetchAzureTTS(response);
            setTtsAudioUrl(ttsData);
          } catch (a) {
  
          }
  
        }
      }


      
      setMessages(prevMessages => {
        const updatedMessages = prevMessages.slice(0, -1);
        updatedMessages.push({ text: response, sentByUser: false });
        return updatedMessages;
      });
      setCustomerMessages(prevMessages => {
        const updatedMessages = prevMessages.slice(0, -1);
        updatedMessages.push({ text: response, sentByUser: false });
        return updatedMessages;
      });

      setLatestResponse(response);
      ChatGPTAIService.addMessageToConversation('assistant', response);
      ChatGPTAIService.addMessageWithoutPrompt('assistant', response);

      setIsSending(false);
    } catch (error) {
      console.error('Error in handleSend:', error);
      setIsSending(false);
    }
  };

  const sendEmailWithSummary = async() => {
    const currentTime = new Date();
    const year = currentTime.getFullYear();
    const month = currentTime.getMonth() + 1; // Months are zero-indexed
    const day = currentTime.getDate();
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const conversationDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;
  
    // Calculate the time interval
    const elapsedMilliseconds = currentTime - startTime;
    const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);
    const intervalMinutes = Math.floor(elapsedSeconds / 60);
    const intervalSeconds = elapsedSeconds % 60;
    const intervalTime = `${intervalMinutes} minutes and ${intervalSeconds} seconds`;
    
    const pdf64 =  await generatePDF(uniqueId,conversationDateTime,intervalTime);
    console.log(pdf64);

    const templateParams = {
      id:uniqueId,
      conversation_date_time: conversationDateTime,
      message: `Summary of the conversation`,
      content:pdf64
    };

    emailjs.send('service_vq3nbag', 'template_pxb3rkk', templateParams, 'OmOO_YXt3yZ8CfLzx')
      .then((response) => {
        console.log('Email successfully sent!', response.status, response.text);
      }, (error) => {
        console.log('Failed to send email:', error);
      });
  };

  const toggleTermsModal = () => {
    setModalVisible(!modalVisible);
  };

const sendSummaryBtn = ()=>{
  sendSummary(true);
}

  const sendSummary = async (bool) => {
    
    // true is only when it was selected by the btn of suummary otherwize it is false and for sending mail purpose.
    if (bool){

    setMessages(prevMessages => [...prevMessages, { text: "typing...", sentByUser: false }]);
    setCustomerMessages(prevMessages => [...prevMessages, { text: "typing...", sentByUser: false }]);

    let response;
    try {
      ChatGPTAIService.addMessageToConversation('user', ElisonIsPickedRef.current?" אני רוצה סיכום הוראות: תכתוב לי סיכום בסיכום תכתוב את כל השיחה האחרונה ביננו בצורה מסודרת שיהיה לי ברור אל תשכח לכתוב את המילה סיכום בפנים אם השיחה קצרה מידי תכתוב שלא נאספו מספיק פרטים בשיחה ואם הלקוח רוצה הוא יכול להתחיל את השיחה מחדש אלו הוראות מערכת ולא הודעה של הלקוח" :"i want a summary prompt: Please summarize all of the recent conversation with the customers to the customer. deeply with exact details  in a much ordered ways for the Vice President Customer Relations personnel of Elysian-Softech to see and to understand everything about the customer and the conversation that took place. Collect all the customers details in the summary If the contact details are missing, do not request them. If the conversation is too brief, indicate that the conversation has ended and the customer can initiate a new one if they choose. Please refrain from requesting any additional information.");
      //sendMessageToMongo('customer', text + instructions.user_instruction_advanced, 'customer');
      response = await ChatGPTAIService.enqueueRequest(ElisonIsPickedRef.current, isStreaming);
      //isNotSummarize= false;
      setIsSummary(false);
      summaryCount+=7;

      setMessages(prevMessages => {
        const updatedMessages = prevMessages.slice(0, -1);
        updatedMessages.push({ text: response, sentByUser: false });
        return updatedMessages;
      });
      setCustomerMessages(prevMessages => {
        const updatedMessages = prevMessages.slice(0, -1);
        updatedMessages.push({ text: response, sentByUser: false });
        return updatedMessages;
      });

      setLatestResponse(response);
   

      sendEmailWithSummary();

      

      setIsSending(false);
    } catch (error) {
      console.error('Error in handleSend:', error);
      setIsSending(false);
    }

  }else {
    try {

      //ChatGPTAIService.addMessageToConversation('user', ElisonIsPicked?"תכתוב לי סיכום בבקשה עם כל פרטי השיחה":"Please summarize all of the recent conversation with the customers to the customer deeply with exact details ways for the Vice President Customer Relations personnel of Elysian-Softech to see and to understand everything about the customer and the conversation that took place, don't write anything in bold. Collect all the customers details in the summary");
  
      //const response = await ChatGPTAIService.enqueueRequest();
      console.log("AvatarIsPicked current", AvatarIsPickedRef.current);
      if (AvatarIsPickedRef.current) sendEmailWithSummary();
      isSummaryRunning = false;
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle the error appropriately
    }
  }
  };
  useEffect(() => {
    sendInitialMessageByAI();
    const updateLayout = () => {
      const width = Dimensions.get('window').width;
      // Assuming 768px is the breakpoint for desktop
      if (width >= 1024) {
        setIsMobileDesign(false);
      } else {
        setIsMobileDesign(true);
      }
    };

    Dimensions.addEventListener('change', updateLayout);
    updateLayout(); // Initial setting

    window.addEventListener('beforeunload', HandleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload',HandleBeforeUnload);
      Dimensions.removeEventListener('change', updateLayout);
    };
  }, [])

  const HandleBeforeUnload = (e) => {
    sendEmailWithSummary();
    const message = "Are you sure you want leave?";
    e.preventDefault();
    

    // Included for legacy support, e.g. Chrome/Edge < 119
    e.returnValue = true;
    return message;
  } 

  useEffect(() => {
    console.log(AvatarIsPicked);
    window.scrollTo(0,0)
    if(AvatarIsPicked){
      scrollViewRefMain.current?.scrollTo({x: 0, y: 0, animated: true})
    }
  }, [AvatarIsPicked]);

  return (
    <View style={{    
        top: 0,
        bottom:0,
        position:'fixed',
        overflowY:'scroll',
        overflowX:'hidden',
        width: '100%'
    }}>
      <ScrollView 
        style={styles.overlay}
        scrollEnabled={!AvatarIsPicked} 
        ref={scrollViewRefMain}
      >
        <Header
            AvatarIsPicked={AvatarIsPicked}
            setAvatarIsPicked={setAvatarIsPicked}
            setElisonIsPicked={setElisonIsPicked}
            setActiveVideoIndex={setActiveVideoIndex}
            setIsFullscreen={setIsFullscreen}
            setIsSummary={setIsSummary}
            isFullscreen={isFullscreen}
            isMobileDesign={isMobileDesign}
            ChatGPTAIService={ChatGPTAIService}
          />
        <TermsModal isVisible={modalVisible} onAccept={handleAcceptTerms} />
        <View style={[styles.container, screenWidth > 1000 && {width: 1000}, (isMobileDesign && AvatarIsPicked && window.self!==window.top) && {marginTop: 20}]}>
          <View style={AvatarIsPicked && {backgroundColor: '#3B3C44'} }>
            { !AvatarIsPicked && <MainTitle isMobileDesign={isMobileDesign} /> }

            <Avatar 
              label={avatarLabel} 
              videoStream={videoStream} 
              ttsAudioUrl={ttsAudioUrl} 
              onVoiceTranscript={handleSend} 
              latestResponse={latestResponse} 
              changeVideo={changeVideo} 
              expressionVideo={videoSource} 
              talkingVideo={videoSources} 
              ElisonState={ElisonIsPicked} 
              activeVideoIndex={activeVideoIndex}
              setActiveVideoIndex={setActiveVideoIndex}
              AvatarIsPicked={AvatarIsPicked}
              isFullscreen={isFullscreen}
              setIsFullscreen={setIsFullscreen}
              isMobileDesign={isMobileDesign}
              isStreamingRef={isStreamingRef}
            />
            {AvatarIsPicked &&(
              <>
                {!isFullscreen &&
                  <MessageList
                    messages={messages}
                    userImage={UserImage}
                    botImage={botImage}
                    scroll={AvatarIsPicked}
                  />
                }
                {isSummary && 
                  <ChatInput 
                    onSend={handleSend} 
                    isSendingDisabled={isSending} 
                    onFileSelected={handleFileSelected} 
                    lang={ElisonIsPicked} 
                    focus={isFirst} 
                    sendSummaryBtn={sendSummaryBtn}
                    ChatGPTAIService={ChatGPTAIService}
                    isMobileDesign={isMobileDesign}
                  />}
              </>
            )}
            { !AvatarIsPicked && <Partners isMobileDesign={isMobileDesign} /> }
          </View>
        </View>
        {!AvatarIsPicked && 
          <TouchableOpacity
              onPress={toggleTermsModal}
              style={!isMobileDesign ? styles.termsContainer : styles.termsMobile}
            >
                <Text style={styles.terms}>By engaging with this AI system, you acknowledge and accept the Privacy Policy and Terms & Conditions, which you can conveniently access and review here:</Text>
                <Text style={[styles.terms, {textDecorationLine:'underline'}]}>Privacy & Terms</Text>
          </TouchableOpacity>
        }
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  overlay: {
    flex:1,
    height: (screenHeight > 800 && screenWidth > 1024) ? screenHeight : screenWidth > 1024 ? 800 : 'auto',
    backgroundColor: '#2D2E35',
    overflowX: 'hidden',
    width: '100%'
  },
  container: {
    flexGrow: 1,
    marginHorizontal: 'auto',
    marginVertical: 0,
    backgroundColor: '#2D2E35',
    height: (screenHeight > 800 && screenWidth > 1024) ? screenHeight : screenWidth > 1024 ? 800 : 'auto',
  },
  termsContainer: {
    flex:1,
    position: 'absolute',
    width: 'auto',
    height: 23,
    alignSelf: 'flex-end',
    right: 100,
    bottom: '5%',
    flexDirection: screenWidth > 1600 ? 'row' : 'column',
    marginBottom: 20
  },
  termsMobile: {
    position: 'relative',
    minHeight: 50,
    alignSelf: 'center',
    alignContent: 'center',
    backgroundColor: '#2D2E35',
    marginTop: 10,
    flexDirection: 'column',
    marginBottom: 20,
  },
  terms: {
    color: '#CECECE',
    fontSize: 18,
    alignSelf: 'center',
    marginHorizontal: 15,
    marginBottom: 20,
    textAlign: 'center',
    minWidth: 130
  }
});
