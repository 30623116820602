import React, { useEffect } from 'react';
import { SafeAreaView, View, StyleSheet } from 'react-native';
import Customer from '~/pages/CustomerPage';
import { AppContextProvider } from '~/contexts/AppContext';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

export default function App() {
  useEffect(() => {
    // Redirect logic
    if (!document.referrer.startsWith('https://elysian-softech.com') && 
        !document.referrer.startsWith('https://life-medic-elysian.azurewebsites.net') && 
        !document.referrer.startsWith('')) {
      window.location.href = 'https://life-medic-elysian.azurewebsites.net';
    }

    // Initialize Google Analytics
    ReactGA.initialize('AW-16458603691');
    ReactGA.pageview(window.location.pathname + window.location.search);

    // Initialize Facebook Pixel
    ReactPixel.init('927311441973804');
    ReactPixel.pageView();
  }, []);


  return (
    <AppContextProvider>
      <SafeAreaView style={styles.container}>
        <View style={styles.container}>
          <Customer />
        </View>
      </SafeAreaView>
    </AppContextProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  }
});
