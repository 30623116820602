import React, { useState, useRef } from 'react';
import { Modal, View, Text, ScrollView, Button, StyleSheet } from 'react-native';

const termsText = `
אליסיאן סופטק בע״מ (להלן: ״אליסיאן״ או ״החברה״), רואה חשיבות רבה בשמירה על פרטיות המשתמשים באתר החברה, שכתובתו www.elysian-softech.com (להלן: ״האתר״)
מסמך זה מפרט מהי מדיניות הפרטיות הנהוגה באתר, וסוקר, בין השאר, את האופן שבו החברה עושה החברה שימוש במידע שנאסף מהמשתמשים באתר.
המידע שייאסף יישמר במאגרי המידע של החברה, והשימוש בו ייעשה בהתאם להוראות מדיניות פרטיות זו או על פי הוראות כל דין. החברה תהיה זכאית, מעת לעת, לשנות או לעדכן את הוראות מדיניות פרטיות זו. מומלץ כי תחזור ותעיין במדיניות זו מעת לעת.

.1 הגדרות
1.1 ״משתמש״: השימוש באתר הינו חופשי. ״משתמש״ הינו כל מי שגולש או עושה כל שימוש אחר באתר החברה.


.2 מסירת פרטים אישיים
חלק מהשירותים באתר דורשים מסירה של פרטים אישיים, אשר עשויים לכלול, בין היתר, שם, כתובת דוא"ל, שם חברה, אמצעי תשלום, ועוד פרטים אישים שמשתמש עשוי להחליט למסור. המשתמש אינו חייב למסור פרטים אלה על פי חוק, אך בלי מסירת פרטים אלה, החברה לא תוכל להעניק למשתמש חלק מהשירותים שהיא מציעה. 

חלק מהשירותים באתר מתבססים על פרסונליזציה. כך למשל, באתר מוטמעת מערכת מבוססת בינה מלאכותית אשר מייעלת את ההתנהלות של החברה מול המשתמשים. השימוש במערכות המוטמעות באתר הינו על אחריותו של המשתמש בלבד ומשתמש אשר מוסר פרטים אישיים באתר עושה זאת על דעת עצמו ומבלי שנדרש לכך. בחתימתו על הצהרת פרטיות זו, המשתמש מצהיר שהוא בגיר ושהוא רשאי על פי דין למסור את המידע שהוא מתכוון למסור בצ׳אט החכם שבאתר.


3. איסוף ושימוש במידע

3.1  בעת השימוש באתר נאסף מידע אודות המשתמשים. האתר עושה שימוש בכלים חדשניים מבוססי בינה מלאכותית, ובכלל זה גם ב-״צ׳אט חכם״ שאיתו יכולים לשוחח המשתמשים.

3.2 חלק מן המידע אשר נאסף על המשתמשים בעת השימוש באתר מזהה את המשתמשים באופן אישי, באמצעות המידע אשר נמסר או מועבר באמצעות האתר על ידי המשתמשים ביודעין (להלן: ״מידע אישי״). האתר אינו אוסף מידע אישי מזהה על המשתמשים אלא אם כן מידע זה נמסר ביוזמת המשתמש בעת השימוש באחד השירותים באתר.

4. מטרת איסוף המידע
השימוש במידע שנאסף, ייעשה על פי מדיניות פרטיות זו או על פי הוראות כל דין, בין היתר לצרכים הבאים –
•	קבלת פניות ממשתמשים וטיפול בהן
•	יצירת קשר עם המשתמשים במידת הצורך, לרבות כדי לספק למשתמש מידע על שירותים ומוצרים שבהם הביע עניין
•	קבלת משוב ממשתמשים 
•	לשם תפעול תקין של האתר ופיתוחו
•	לאפשר למשתמש להשתמש בשירותים שונים באתר
•	להפיק ולנתח מידע סטטיסטי
•	לשפר, לקדם ולהעשיר את התכנים המוצעים באתר
•	לצרכי בקרה ואבטחת מידע של האתר והשימוש בו
•	לצרכים משפטיים
•	לכל מטרה אחרת שפורט עליה במדיניות פרטיות זו או בהסכם אחר בין הצדדים

5. מסירת מידע לצדדים שלישיים
החברה לא תעביר ללא הסכמת המשתמשים פרטים אישיים לצדדים שלישיים, אלא במקרים המפורטים להלן:

5.1 לנותני שירותים, לצורך מתן השירותים הניתנים לחברה בקשר עם עסקיה 

5.2 לצדדים שלישיים אשר יעבדו את המידע לטובת הפעלת השירותים הניתנים באתר.

החברה מעמידה לרשות המשתמשים ״צ׳אט חכם״ מבוסס בינה מלאכותית. המידע אשר נמסר ע״י המשתמשים בעת השימוש בצ׳אט החכם מועבר לצד שלישי לצורך עיבוד ולצורך ביצוע הליך של למידת מכונה בהתבסס על המידע שנמסר. אין למסור בצ׳אט החכם מידע רגיש שהמשתמש איננו מעוניין שצדדים שלישיים (שאינם המשתמש או החברה) ייחשפו אליו.
5.3 לצדדים שלישיים, לצורך בירור חשד לביצוע פעולות מנוגדות לדין ע״י משתמשים באתר

5.4 לצדדים שלישיים, במסגרת הליכים משפטיים שהחברה מהווה צד להם

5.5 בכל מקרה שבו החברה תסבור כי מסירת המידע עשויה למנוע נזק כלשהו לצד שלישי כלשהו

5.6 על מנת לקרוא עוד על השימוש שעושים במידע הנמסר על ידך ב״צ׳אט החכם״ צדדים שלישיים שאליהם אנחנו מעבירים מידע, יש לעיין באתר שבקישור:https://openai.com/policies/privacy-policy

`;


function TermsModal({ isVisible, onAccept }) {
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const scrollViewRef = useRef();

    const handleScroll = ({ nativeEvent }) => {
        if (isCloseToBottom(nativeEvent)) {
            setIsButtonEnabled(true);
        }
    };

    const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }) => {
        const paddingToBottom = 20;
        return layoutMeasurement.height + contentOffset.y >= contentSize.height - paddingToBottom;
    };

    return (
        <Modal
        animationType="slide"
        transparent={true} // Set the modal background to be transparent
        visible={isVisible}
      >
        <View style={styles.modalBackground}> {/* Add this new style */}
          <View style={styles.container}>
            <ScrollView
              style={styles.scrollView}
              onScroll={handleScroll}
              ref={scrollViewRef}
            >
              <Text style={styles.textTitle}>מדיניות פרטיות</Text>
              <Text style={styles.textDate}>עודכן לאחרונה ב 4.2024</Text>
              <Text style={styles.text}>
                {termsText}
              </Text>
            </ScrollView>
            <Button
              title="Accept"
              onPress={onAccept}
              disabled={!isButtonEnabled}
            />
          </View>
        </View>
      </Modal>
    );
}

const styles = StyleSheet.create({
    modalBackground: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
    },
    container: {
        width: '80%', // Adjust width as needed
        height: '60%', // Adjust height as needed
        justifyContent: 'center',
        alignItems: 'center',
        margin: 20,
        backgroundColor: '#FFF', // Background color for the modal window
        padding: 20, // Optional: for internal padding
    }, container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        margin: 20,
    },
    scrollView: {
        marginHorizontal: 20,
        marginVertical: 20,
        paddingHorizontal: 10,
        backgroundColor: '#FFF',
        flex: 1,
    },
    text: {
        fontSize: 16,
        lineHeight: 27,
        color: '#344054'
    },
    textTitle: {
      fontSize: 18,
      fontWeight: 700,
      lineHeight: 27,
      color: '#344054'
    },
    textDate: {
      fontSize: 14,
      fontWeight: 700,
      lineHeight: 21,
      color: '#8F959F'
    }
});

export default TermsModal;
