import React, { useState, useEffect, useRef, useContext, useReducer } from 'react';
import { View, Text, Animated, TouchableOpacity, Dimensions, StyleSheet, Image, ImageBackground, } from 'react-native';
import maximizeIcon from '~/assets/images/maximize.png'
import minimizeIcon from '~/assets/images/minimize.png'
import AppContext from '~/contexts/AppContext'
import rectangleImage from '~/assets/images/Rectangle.png';
import volumeOffIcon from '~/assets/images/volumeOff.png';
import volumeOnIcon from '~/assets/images/volume.png';
import TomerPic from '~/assets/images/Tomer.jpg'
import AviadPic from '~/assets/images/Aviad.jpg'
import AdrianPic from '~/assets/images/Adrian.jpg'
import AssafPic from '~/assets/images/Assaf.jpg'
import YuriPic from '~/assets/images/Yuri.jpg'
import Elison from '~/assets/images/AIElison.jpeg'
import chooseIcon from '~/assets/images/choose.png'
import { Video, ResizeMode } from 'expo-av';
import TomerVideo from '~/assets/videos/Tomer.mp4';
let screenHeight = Dimensions.get('window').height;
let screenWidth = Dimensions.get('window').width;

const Avatar = ({ 
    label, 
    AvatarIsPicked, 
    ttsAudioUrl, 
    latestResponse, 
    changeVideo, 
    expressionVideo, 
    talkingVideo, 
    activeVideoIndex, 
    setActiveVideoIndex,
    isFullscreen,
    setIsFullscreen,
    isMobileDesign,
    isStreamingRef
  }) => {
  const { isStreaming, setIsStreaming } = useContext(AppContext);
  const { responsiveHeight, responsiveWidth } = useContext(AppContext);
  const { setIsRecording } = useContext(AppContext);
  const { recognitionPat } = useContext(AppContext);
  const videoRef = useRef(null);
  const ttsAudioRef = useRef(new Audio(ttsAudioUrl));
  const fadeAnim = useRef(new Animated.Value(1)).current;
  const videoSources = [expressionVideo, ...talkingVideo];

  // Transition duration
  const transitionDuration = 200;
  const responsiveHW = responsiveHeight < responsiveWidth ? responsiveHeight : responsiveWidth;

  // Functions to fade in and fade out
  const fadeOut = () => {
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: transitionDuration,
      useNativeDriver: true,
    }).start();
  };

  const fadeIn = () => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: transitionDuration,
      useNativeDriver: true,
    }).start();
  };

  const onSelectAvatar = (avatarName) => {
    changeVideo(avatarName);

  };

  // Function to change the video source
  const changeVideoSource = (isTTS) => {
    fadeOut();

    setTimeout(() => {
      const videoIndex = isTTS ? Math.floor(Math.random() * talkingVideo.length) + 1 : 0; // +1 to skip the expression video which is at index 0
      setActiveVideoIndex(videoIndex);
      fadeIn();
    }, transitionDuration);
  };

  useEffect(() => {
    // Function to reset the audio source
    const resetAudioSource = () => {
      if (ttsAudioRef.current) {
        ttsAudioRef.current.pause();
        ttsAudioRef.current.src = ''; // Reset source
        changeVideoSource(false);

      }
    };

    // Only set up audio if isStreaming is true and ttsAudioUrl is available
    if (ttsAudioUrl && isStreaming) {
      ttsAudioRef.current.src = ttsAudioUrl;
      changeVideoSource(true); // Switch to talking video
      ttsAudioRef.current.play();

      // Change back to the expression video when the audio ends
      ttsAudioRef.current.onended = () => {
        resetAudioSource(); // Also reset the audio source when the audio ends
        changeVideoSource(false); // Switch back to expression video
      };
    } else {
      resetAudioSource(); // Pause and reset the audio if isStreaming is false
    }

    // Cleanup function to reset the audio when the component is unmounted or dependencies change
    return () => resetAudioSource();

  }, [ttsAudioUrl, isStreaming]);



  useEffect(() => {
    changeVideoSource();
  }, [AvatarIsPicked]);

  const toggleFullscreen = () => {
    if (recognitionPat) {

      recognitionPat.stop();
    }
    setIsRecording(false);
    setIsFullscreen(!isFullscreen);
  };
  // Toggle Streaming state
  const toggleStreaming = () => {

    setIsStreaming(!isStreaming);
    isStreamingRef.current=!isStreaming;

  };

  const getLimitedResponse = (response) => {
    const lines = response.split('. ').map(line => line.trim()).filter(line => line);
    if (lines.length > 2) {
      return [lines.slice(0, 2).join('. '), '...'].join('. ');
    }
    return lines.join('. ');
  };

  return (
    <View style={[styles.container, AvatarIsPicked && styles.containerChat]}>
      {/* Parent View to hold both video and button container */}
      <View style={[styles.avatarsContainer,(isFullscreen && isMobileDesign) && styles.avatarsContainerFullscreenMobile]}>
        {/* Animated View for the video */}
        { AvatarIsPicked &&
        <Animated.View style={{ opacity: fadeAnim }}>
          {/* Video and other elements */}
          {videoSources.map((src, index) => (
              <Video
                key={index}
                source={src}
                style={[!isFullscreen ? 
                  styles.avatarVideoSmall : isMobileDesign ? styles.avatarVideofullscreenMobile : styles.avatarVideofullscreen,
                  {display: activeVideoIndex === index ? 'block' : 'none' }]}
                videoStyle={!isFullscreen ? styles.avatarVideoSmall : isMobileDesign ? styles.avatarVideofullscreenMobile : styles.avatarVideofullscreen}
                shouldPlay={true}
                useNativeControls={false}
                resizeMode="contain"
                isLooping
                isMuted
              />
            ))}
          <View style={!isFullscreen ? styles.iconsOverlay : styles.iconsOverlayFullScreen}>
            {/* Maximize Button */}
            <TouchableOpacity onPress={toggleFullscreen}>
              <Image source={isFullscreen ? minimizeIcon : maximizeIcon} style={!isFullscreen ? styles.icon : styles.iconFullScreen} />
            </TouchableOpacity>
          </View>
          <View>
            {/* Volume Toggle Button */}
            <TouchableOpacity onPress={toggleStreaming} style={!isFullscreen ? styles.avatarVoiceToggle : styles.avatarVoiceToggleFullscreen}>
              <Text style={styles.avatarVoiceToggleText}>Voice is - {isStreaming ? "On" : "Off"}</Text>
            </TouchableOpacity>
          </View>
          {isFullscreen && isStreaming && latestResponse && (
            <View style={styles.subtitleStyleContainer}>

              <Text style={styles.subtitleStyle} numberOfLines={2}>
                {getLimitedResponse(latestResponse)}
              </Text>
            </View>

          )}
        </Animated.View>
        }

        {!isFullscreen && !AvatarIsPicked && (
          <View>
            <View style={!isMobileDesign ? styles.buttonContainer : [styles.buttonContainerMobile,{width: screenWidth}]}>
              <View style={!isMobileDesign ? styles.avatarButton : styles.avatarButtonMobile}>
                <TouchableOpacity onPress={() => onSelectAvatar('Tomer')}>
                  <Image source={TomerPic} style={ !isMobileDesign ? styles.avatarImage : styles.avatarImageMobile} />
                </TouchableOpacity>
                <View style={!isMobileDesign ? styles.avatarNameContainer : styles.avatarNameContainerMobile}>
                  <Text style={!isMobileDesign ? styles.avatarName : styles.avatarNameMobile}>Mr. Tomer Paz</Text>
                  <Text style={!isMobileDesign ? styles.avatarName : styles.avatarNameMobile}>English Speaker</Text>
                </View>
              </View>
              <View style={!isMobileDesign ? styles.avatarButton : styles.avatarButtonMobile}>
                <TouchableOpacity onPress={() => onSelectAvatar('Aviad')}>
                  <Image source={AviadPic} style={ !isMobileDesign ? styles.avatarImage : styles.avatarImageMobile} />
                </TouchableOpacity>
                <View style={!isMobileDesign ? styles.avatarNameContainer : styles.avatarNameContainerMobile}>
                  <Text style={!isMobileDesign ? styles.avatarName : styles.avatarNameMobile}>Mr. Aviad Afengar</Text>
                  <Text style={!isMobileDesign ? styles.avatarName : styles.avatarNameMobile}>English Speaker</Text>
                </View>
              </View>
              <View style={!isMobileDesign ? styles.avatarButton : styles.avatarButtonMobile}>
                <TouchableOpacity onPress={() => onSelectAvatar('Adrian')}>
                  <Image source={AdrianPic} style={ !isMobileDesign ? styles.avatarImage : styles.avatarImageMobile} />
                </TouchableOpacity>
                <View style={!isMobileDesign ? styles.avatarNameContainer : styles.avatarNameContainerMobile}>
                  <Text style={!isMobileDesign ? styles.avatarName : styles.avatarNameMobile}>Dr. Adrian Paz</Text>
                  <Text style={!isMobileDesign ? styles.avatarName : styles.avatarNameMobile}>English Speaker</Text>
                </View>
              </View>
              <View style={!isMobileDesign ? styles.avatarButton : styles.avatarButtonMobile}>
                <TouchableOpacity onPress={() => onSelectAvatar('Assaf')}>
                  <Image source={AssafPic} style={ !isMobileDesign ? styles.avatarImage : styles.avatarImageMobile} />
                </TouchableOpacity>
                <View style={!isMobileDesign ? styles.avatarNameContainer : styles.avatarNameContainerMobile}>
                  <Text style={!isMobileDesign ? styles.avatarName : styles.avatarNameMobile}>Mr. Assaf Matza</Text>
                  <Text style={!isMobileDesign ? styles.avatarName : styles.avatarNameMobile}>English Speaker</Text>
                </View>
              </View>
              <View style={!isMobileDesign ? styles.avatarButton : styles.avatarButtonMobile}>
                <TouchableOpacity onPress={() => onSelectAvatar('Yuri')}>
                  <Image source={YuriPic} style={ !isMobileDesign ? styles.avatarImage : styles.avatarImageMobile} />
                </TouchableOpacity>
                <View style={!isMobileDesign ? styles.avatarNameContainer : styles.avatarNameContainerMobile}>
                  <Text style={!isMobileDesign ? styles.avatarName : styles.avatarNameMobile}>Mr. Yuri Paikin</Text>
                  <Text style={!isMobileDesign ? styles.avatarName : styles.avatarNameMobile}>English Speaker</Text>
                </View>
              </View>
              <View style={!isMobileDesign ? styles.avatarButton : styles.avatarButtonMobile}>
                <TouchableOpacity onPress={() => onSelectAvatar('Elison')}>
                  <Image source={Elison} style={ !isMobileDesign ? styles.avatarImage : styles.avatarImageMobile} />
                </TouchableOpacity>
                <View style={!isMobileDesign ? styles.avatarNameContainer : styles.avatarNameContainerMobile}>
                  <Text style={!isMobileDesign ? styles.avatarName : styles.avatarNameMobile}>גברת אליסון</Text>
                  <Text style={!isMobileDesign ? styles.avatarName : styles.avatarNameMobile}>דוברת עברית</Text>
                </View>
              </View>
            </View>
          </View>
        )}
      </View>
    </View>
  );
};

const responsive = parseInt(localStorage.getItem('responsive'), 10);// claculate when responsive was 1278 0.03*responsive~40
const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'visible',
    position: 'relative',
  },
  containerChat: {
    backgroundColor: '#3B3C44',
    paddingHorizontal: 40
  },
  buttonContainer: { // space between video and buttons
    justifyContent: 'space-between',
    height: '100%',
    flexDirection: 'row', // adjust as per requirement
  },
  buttonContainerMobile: {
    justifyContent: 'space-between',
    height: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  sideButton: {
    resizeMode: 'contain',
  },
  iconsOverlay: {
    position: 'absolute',
    alignItems: 'center',
    right: 5,
    top: 5,
    padding:3,
    backgroundColor: '#7C92A9'
  },
  iconsOverlayFullScreen: {
    position: 'absolute',
    right: 10,
    bottom: 10,
    width: 44,
    height: 44,
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    backgroundColor: '#7C92A9'
  },
  sharedIconBackground: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 24,
    height: 24,
    opacity: 0.8
  },
  iconButton: {
    alignItems: 'center',
  },
  icon: {
    width: 20,
    height: 20,
    alignSelf: 'center',
  },
  iconFullScreen: {
    width: 35,
    height: 35,
    alignSelf: 'center',
  },
  recorderFullscreenStyle: {
    position: 'absolute',
    left: '50%',
    bottom: 0.016 * responsive, // You can adjust this value as needed to position the icons
    transform: [{ translateX: -0.039 * responsive }], // This will center the icons
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 1, // Make sure this is above other elements
  },
  subtitleStyle: {
    position: 'absolute',
    zIndex: 1,
    backgroundColor: 'transparent',
    color: 'white',
    fontSize: 0.02 * responsive,
    width: responsive * 0.7, // Full width of the parent
    textAlign: 'center', // Center the text
    bottom: responsive * 0.045,
    left: -responsive * 0.35
  },
  subtitleStyleContainer: {
    position: 'absolute',
    left: '50%',
    bottom: 0.016 * responsive, // You can adjust this value as needed to position the icons
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 1,
    width: responsive
  },
  avatarsContainer: {
    flexDirection: 'row', 
    alignItems: 'center',
  },
  avatarsContainerFullscreenMobile: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: screenHeight - 45,
    marginLeft:-40
  },
  avatarButton: {
    flexDirection: 'column',
    backgroundColor: '#57585d',
    marginHorizontal: 10,
    alignItems: 'center',
    alignContent: 'center',
    alignSelf: 'center'
    // Add any additional styling you need here
  },
  avatarButtonMobile: {
    flexDirection: 'column',
    backgroundColor: '#57585d',
    marginHorizontal: '4%',
    alignItems: 'center',
    alignContent: 'center',
    alignSelf: 'center',
    minWidth: '22%',
    marginBottom: 10
  },
  avatarImage: {
    width: 150,
    height: 150
  },
  avatarImageMobile: {
    width: Math.round(screenWidth/4),
    minHeight: 90
  },
  avatarNameContainer: {
    width: 150,
    height: 66,
    padding: 10,
  },
  avatarNameContainerMobile: {
    width: Math.round(screenWidth/4),
  },
  avatarName: {
    color: 'white', // Set the text color
    fontSize: 16,
    alignSelf: 'center',
    lineHeight: 22
  },
  avatarNameMobile: {
    color: 'white', // Set the text color
    fontSize: 10,
    alignSelf: 'center',
    lineHeight: 12
  },
  avatarVideoSmall: {
    width: 174,
    height: 174
  },
  avatarVideofullscreen: {
    width: screenWidth < 1200 ? 900 : screenHeight-75,
    height: screenHeight-75
  },
  avatarVideofullscreenMobile: {
    width: screenWidth,
    height: screenWidth,
    justifyContent: 'flex-start'
  },
  avatarVoiceToggle: {
    width: 174,
    height: 38,
    backgroundColor: '#5A6BF7',
    alignContent: 'center',
    justifyContent: 'center'
  },
  avatarVoiceToggleText: {
    color: '#ffffff',
    fontSize: 16,
    alignSelf: 'center'
  },
  avatarVoiceToggleFullscreen: {
    position: 'absolute',
    width: 174,
    height: 38,
    backgroundColor: '#7D1173',
    alignContent: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    bottom:10,
  }
});


export default Avatar;
