import jsPDF from 'jspdf';
import ChatGPTAIService from './ChatGPTAIService';
import rubikFontBase64 from './Rubik-Black-normal';

export const generatePDF = async (id, date, time) => {

    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 20; // Margin size
    const maxLineWidth = pageWidth - margin * 2; // Maximum line width
    const lineHeight = 10; // Adjust line height as needed
    let currentHeight = margin;

    doc.addFileToVFS('Rubik-Black-normal.ttf', rubikFontBase64);
    doc.addFont('Rubik-Black-normal.ttf', 'Rubik-Black', 'normal');

    doc.setFont('Rubik-Black', 'normal');

    // Adding conversation ID and date at the top


    // Add the Base64 encoded font to jsPDF's virtual file system


    doc.setFontSize(12); // Adjust font size as needed
    doc.text(`Conversation ID: ${id}`, margin, currentHeight);
    currentHeight += lineHeight; // Increment height after adding text
    doc.text(`Conversation started before: ${time}`, margin, currentHeight);
    currentHeight += lineHeight; // Increment height after adding text
    doc.text(`Date: ${date}`, margin, currentHeight);
    currentHeight += lineHeight * 2; // Add extra space before conversation text

    ChatGPTAIService.conversationWithoutPrompt.forEach((message, index) => {
        // Split text to fit the page
        let lines = doc.splitTextToSize(`${message.role}: ${message.content}`, maxLineWidth);

        // Check if the text goes beyond the current page
        if (currentHeight + lines.length * lineHeight > pageHeight) {
            doc.addPage();
            currentHeight = margin;
        }

        // Print the text and update the current height
        doc.text(margin, currentHeight, lines);
        currentHeight += lines.length * lineHeight;
    });

    return doc.output('datauristring');
    //
    
};
