import React, { useState, createContext, useRef } from 'react';
import { Dimensions } from 'react-native';

const screenHeight = Dimensions.get('window').height;
const screenWidth = Dimensions.get('window').width;

localStorage.setItem('responsive',screenHeight<screenWidth?screenHeight.toString():(screenWidth).toString())

const phone = screenHeight>screenWidth&&screenHeight<800?"yes":"no";
localStorage.setItem("phone",phone);

const AppContext = createContext({
  customerMessages: [],
  setCustomerMessages: () => {},
  isStreaming: false,
  setIsStreaming: () => {},
  isRecording: false,
  setIsRecording:()=>{},
  recognitionPat:null,
  setRecognitionPat:()=>{},
  jsonData: {},
  setJsonData: () => {}, 
  responsiveWidth:screenWidth,
  setResponsiveWidth:()=>{},
  responsiveHeight:screenHeight,
  SetResponsiveHeight:()=>{},
});

export const AppContextProvider = ({ children }) => {
  const [customerMessages, setCustomerMessages] = useState([]);
  const [isStreaming, setIsStreaming] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recognitionPat, setRecognitionPat] = useState(null);
  const [jsonData, setJsonData] = useState({});
  const [responsiveWidth,setResponsiveWidth] =useState(screenWidth);
  const [responsiveHeight,SetResponsiveHeight] =useState(screenHeight); 

  return (
    <AppContext.Provider value={{  customerMessages,
        setCustomerMessages,
        isStreaming,
        setIsStreaming,isRecording,
        setIsRecording, recognitionPat, setRecognitionPat,jsonData, setJsonData,responsiveWidth,setResponsiveWidth,responsiveHeight,SetResponsiveHeight,}}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
