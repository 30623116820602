import React from 'react';
import { View, Text, Dimensions, StyleSheet } from 'react-native';
const screenHeight = Dimensions.get('window').height;
const screenWidth = Dimensions.get('window').width;

const MainTitle = ({isMobileDesign}) => {
    return (
        <View style={[styles.container,  isMobileDesign && {width: screenWidth, height: 220, marginBottom: 10, marginTop: 100}]}>
            { !isMobileDesign ? (
                <>
                    <View style={styles.title}>
                        <Text style={styles.titleText}>Choose an Avatar to talk to</Text>
                    </View>
                    <View style={styles.subTitle}>
                        <Text style={styles.subTitleMobileText}>Hi there! Welcome to our AI assistant service. You have the unique opportunity to</Text>
                        <Text style={styles.subTitleMobileText}>personalize your experience by choosing the persona of one of our esteemed team members:</Text>
                    </View>
                </>
            ) : (
                <>
                    <View style={styles.subTitleMobile}>
                        <Text style={styles.subTitleText}>Hi there! Welcome to our AI</Text>
                        <Text style={styles.subTitleText}>assistant service. You have the</Text>
                        <Text style={styles.subTitleText}>unique opportunity to personalize your</Text>
                        <Text style={styles.subTitleText}>experience by choosing the persona of</Text>
                        <Text style={styles.subTitleText}>one of our esteemed team members:</Text>
                    </View>
                    <View style={styles.titleMobile}>
                        <Text style={styles.titleMobileText}>Choose an Avatar to talk to</Text>
                    </View>
                </>
            )}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        width: 780,
        height: 120,
        alignItems: 'center',
        alignSelf: 'center',
        marginTop: screenHeight > 800 ? '20%' : '10%',
    },
    title: {
        backgroundColor: '#5A6BF7',
        width: 274,
        height: 46,
        justifyContent: 'center'
    },
    titleText: {
        alignSelf: 'center',
        color: '#ffffff',
        fontSize: 16
    },
    subTitle: {
        width: 780,
        height: 50,
        marginTop: 20,
        alignSelf: 'center',
        alignItems: 'center',
    },
    subTitleText: {
        fontSize: 18,
        lineHeight: 22,
        color: '#ffffff'
    },
    titleMobile: {
        backgroundColor: '#5A6BF7',
        width: '92%',
        height: 46,
        justifyContent: 'center',
    },
    titleMobileText: {
        alignSelf: 'center',
        color: '#ffffff',
        fontSize: 16
    },
    subTitleMobile: {
        width: 780,
        height: 150,
        marginTop: 20,
        alignSelf: 'center',
        alignItems: 'center',
    },
    subTitleMobileText: {
        fontSize: 18,
        lineHeight: 22,
        color: '#ffffff'
    }
})

export default MainTitle;