import React, { useEffect, useState } from 'react';
import { View, Text, Image, StyleSheet } from 'react-native';


const Message = ({ text, sentByUser, userImage, botImage }) => {
  const isUserMessage = sentByUser;
  const containerStyles = [styles.messageContainer, isUserMessage ? styles.userContainer : styles.botContainer];
  const imageStyles = [styles.profilePic, isUserMessage ? styles.userImage : styles.botImage];
  const [currentTime,setCurrentTime] = useState();


  // Function to format time
  const formatTime = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return hours + ':' + minutes + ' ' + ampm;
  };

  useEffect(() => {
    setCurrentTime(formatTime(new Date()));
  },[])

  // Get current time

  return (
    <View style={containerStyles}>
      {!isUserMessage && <Image source={botImage} style={imageStyles} />}
      <View style={styles.messageBubble}>
        <Text style={isUserMessage ? styles.userText : styles.botText}>
          {text}
        </Text>
        <Text style={isUserMessage ? styles.userTime : styles.botTime}>{currentTime}</Text>
      </View>
      {isUserMessage && <Image source={userImage} style={imageStyles} />}
    </View>
  );
};
const responsive = parseInt(localStorage.getItem('responsive'), 10);// claculate when responsive was 1278 0.03*responsive~40
const styles = StyleSheet.create({

  messageContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 0.02 * responsive,
    marginVertical: 0.004 * responsive,
  },
  userContainer: {
    justifyContent: 'flex-end',
  },
  botContainer: {
    justifyContent: 'flex-start',
  },
  profilePic: {
    width: 48,
    height: 48,
  },
  userImage: {
    marginLeft: 0.006 * responsive,
    alignSelf: 'flex-end',
    marginBottom: 0.035 * responsive,
  },
  botImage: {
    marginRight: 0.006 * responsive,
    alignSelf: 'flex-start',
    marginTop: 0.009 * responsive,
  },
  messageBubble: {
    maxWidth: '70%',
    minWidth: '6%',
    padding: 0.008 * responsive,
    flexShrink: 0.0008 * responsive,
  },
  userText: {
    color: "rgba(255, 255, 255, 1)",
    backgroundColor: "#596DF0",
    lineHeight: 18,
    fontSize: 14,
    paddingHorizontal: 14,
    paddingVertical: 10,
    shadowRadius: 0.0016 * responsive,
    shadowColor: 'rgb(16, 24, 40)',
    shadowOpacity: 0.001 * responsive,
    shadowOffset: { width: 0, height: 0.0008 * responsive },
  },
  botText: {
    color: 'black',
    backgroundColor: "#F2F4F7",
    fontSize: 14,
    lineHeight: 18,
    paddingHorizontal: 14,
    paddingVertical: 10
  },
  userTime: {
    fontSize: 14,
    lineHeight: 21,
    color: '#B6B6B6',
    textAlign: "right",
    marginTop: 0.003 * responsive,
  },
  botTime: {
    fontSize: 14,
    lineHeight: 21,
    color: '#B6B6B6',
    textAlign: "left",
    marginTop: 0.003 * responsive,
  },
});

export default Message;
