class ChatGPTAIService {
  constructor() {
    this.conversation = [];
    this.conversationWithoutPrompt = [];
    this.requestQueue = [];
    this.isProcessing = false;
  }

  // Method to add a request to the queue
  enqueueRequest(ElisonIsPicked, isStreaming) {
    return new Promise((resolve, reject) => {
      this.requestQueue.push({ ElisonIsPicked, isStreaming, resolve, reject });
      this.processQueue();
    });
  }

  // Method to process the queue
  async processQueue() {
    if (this.isProcessing || this.requestQueue.length === 0) {
      return;
    }

    this.isProcessing = true;
    const { ElisonIsPicked, isStreaming, resolve, reject } = this.requestQueue.shift();

    try {
      let response;
     
      response = await this.sendToChatGPT(ElisonIsPicked, isStreaming);
      
      resolve(response);
    } catch (error) {
      reject(error);
    } finally {
      this.isProcessing = false;
      this.processQueue(); // Move to the next request
    }
  }

  async sendToChatGPT() {
    const maxRetries = 7;
    let attempts = 0;

    while (attempts < maxRetries) {
      try {
        const response = await fetch(`${process.env.GPT_SERVER}/api/english/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            conversation: this.conversation
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        const lastMessage = data.choices[0].message.content.trim();

        return lastMessage;

      } catch (error) {
        attempts++;
        console.error(`Attempt ${attempts} failed:`, error);

        if (attempts === maxRetries) {
          return 'Your connection is not stable, try to reload the page or check your wifi settings';
        }
      }
    }
  }

  async sendToClaude() {
    const maxRetries = 1;
    let attempts = 0;
    while (attempts < maxRetries) {
      try {
        const response = await fetch(`${process.env.GPT_SERVER}/api/hebrew/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'anthropic-version': '2024-02-29',
          },
          body: JSON.stringify({
            system:this.conversation[0].content,
            messages: this.conversation.slice(2),
            model: 'claude-3-opus-20240229',
            max_tokens: 4096,
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        const lastMessage =data[0].text
      
        return lastMessage;
      } catch (error) {
        attempts++;
        console.error(`Attempt ${attempts} failed:`, error);
        if (attempts === maxRetries) {
          return 'Your connection is not stable, try to reload the page or check your internet settings';
        }
      }
    }
  }

  addMessageToConversation(role, content) {
    this.conversation.push({ role, content });
  }

  getLastRole() {
    return this.conversation.at(-1)?.role;
  }
  
  
  addMessageWithoutPrompt(role, content) {
    this.conversationWithoutPrompt.push({ role, content });
  }

  deleteLastMessageFromConversation() {
    const lastIndex = this.conversation.map(message => message.role).lastIndexOf('user');

    if (lastIndex !== -1) {
      // Remove the message if found
      this.conversation.splice(lastIndex, 1);
    } else {
      console.warn('No user messages to delete in the conversation.');
    }
  }

  
  
}



export default new ChatGPTAIService();
