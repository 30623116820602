import React, { useState, useRef, useContext } from 'react';
import { View, TouchableOpacity, Image } from 'react-native';
import { pdfjs } from "react-pdf";
import Tesseract from 'tesseract.js';

//import * as Worker from 'pdfjs-dist/build/pdf.worker.mjs';

import AppContext from '~/contexts/AppContext';
pdfjs.GlobalWorkerOptions.workerSrc = Worker;


const OCR = ({ onFileSelected }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const fileInputRef = useRef(null);
  const { responsiveHeight } = useContext(AppContext);

  const processPDF = async (file) => {
    try {
      const pdf = await pdfjs.getDocument(URL.createObjectURL(file)).promise;
      const numPages = pdf.numPages;
      let allText = '';

      for (let pageNum = 1; pageNum <= numPages; pageNum++) {
        const page = await pdf.getPage(pageNum);
        const textContent = await page.getTextContent();
        const pageText = textContent.items.map(item => item.str).join(' ');
        allText += pageText + '\n'; 
      }
      return allText;
    } catch (error) {
      console.error('Error processing PDF file:', error);
      return '';
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsProcessing(true);
      try {
        let text = '';
        if (file.type === 'application/pdf') {
          text = await processPDF(file);
        } else {
          const result = await Tesseract.recognize(file, 'eng');
          text = result.data.text;
        }
        console.log(text);
    
        onFileSelected({ fileName: file.name, text: text });
      } catch (error) {
        console.error('OCR processing error:', error);
      } finally {
        setIsProcessing(false);
        event.target.value = '';
      }
    }
  };
  

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  return (
    <View style={{ alignItems: 'center', justifyContent: 'center' }}>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
        disabled={isProcessing}
        accept="image/*,application/pdf"
      />
      <TouchableOpacity onPress={triggerFileInput} style={{ justifyContent: 'center', alignItems: 'center', padding: 5 }}>
        <Image
          source={require('~/assets/images/fileBtn.png')}
          style={{ width: 35, height: 35 }}
        />
      </TouchableOpacity>
    </View>
  );
};

export default OCR;
